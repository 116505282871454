$aos-distance: 2em;
@import '../../node_modules/aos/src/sass/aos.scss';

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    word-break: break-word;
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

.box {
    border-radius: 4px !important;
}

.sky-gradient-1 {
    background: linear-gradient(to bottom, #020111 10%,#3a3a52 100%);
}

.is-size-1-custom {
    font-size: 3rem;
}

.is-size-2-custom {
    font-size: 2.5rem;
}

.is-size-3-custom {
    font-size: 2rem;
}

.is-size-4-custom {
    font-size: 1.5rem;
}

.is-size-5-custom {
    font-size: 1.25rem;
}

.is-size-6-custom {
    font-size: 1rem;
}

.is-size-7-custom {
    font-size: 0.75rem;
}

.navbar-item:focus:not(.router-link-active):not(:hover){
    background-color: unset !important;
}

.shadow-effect {
    box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
}

.navbar-burger:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}
.navbar-center-menu {
    margin-left: auto;
    margin-right: auto;
}
.navbar-item-center {
    display: flex;
    justify-content: center !important;
}
.navbar-item-center-dropdown {
    flex-direction: column !important;
    text-align: center !important;
}
.navbar-dropdown-no-padding {
    padding-top: unset !important;
    padding-bottom: unset !important;
}
.navbar-dropdown.is-boxed {
    border-radius: 4px !important;
}
.navbar-dropdown a.navbar-item {
    border-radius: 4px !important;
}
.display-flex {
    display: flex;
}
.hero-absolute {
    position: absolute;
}
.hero-fixed {
    position: fixed;
}
.hero.has-background {
    overflow: hidden;
}
.is-transparent {
    opacity: .3;
}
.is-transparent-2 {
    opacity: .5;
}
.is-transparent-3 {
    opacity: .8;
}
.is-transparent-4 {
    opacity: .65;
}
.hero-background-large {
    position: relative;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
}

.flex-end {
    align-self: flex-end;
}

.width-100p {
    width: 100%;
}
.height-100p {
    height: 100%;
}
.height-100vh {
    height: 100vh;
}
.height-40vh {
    height: 40vh;
}

.width-100-height-100 {
    width: 100%;
    height: 100%;
}

.height-200px {
    height: 200px;
}

.circle-wrapper {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
}

.inside-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    line-height: 120px;
    text-align: center;
    margin-top: 15px;
    margin-left: 15px;
    position: absolute;
    font-weight: 700;
    font-size: 2em;
    overflow: hidden;
}
.inside-circle-numbers div {
    flex: 0 0 120px;
}

.circle-box-margin-top {
    margin-top: -80px;
}

.circle-progress-wrapper {
    position: absolute;
    width: 150px;
    height: 150px;
    transform: rotate(-90deg);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.circle-progress {
    stroke-dasharray: 424.12; // 2 * PI * 67.5
    stroke-dashoffset: 424.12; // 2 * PI * 67.5
    stroke-width: 15px;
    fill: transparent;
    stroke-linecap: round;
}

/* Circle 1: Progress & Counter */
@keyframes circle-progress-animation-1 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 352.02; // 2 * PI * 67.5 * (100 - 17) / 100
    }
}
@keyframes circle-number-animation-1 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -2040px; // 120 * 17
    }
}
[data-aos="circle-progress-animate-1"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-1 .36s linear forwards;
            -webkit-animation: circle-progress-animation-1 .36s linear forwards;
        }
        .inside-circle-numbers-animate-1 {
            animation: circle-number-animation-1 .36s linear forwards;
            animation-timing-function: steps(17);
            -webkit-animation: circle-number-animation-1 .36s linear forwards;
            -webkit-animation-timing-function: steps(17);
        }
    }
}

/* Circle 2: Progress & Counter */
@keyframes circle-progress-animation-2 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 309.60; // 2 * PI * 67.5 * (100 - 27) / 100
    }
}
@keyframes circle-number-animation-2 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -3240px; // 120 * 27
    }
}
[data-aos="circle-progress-animate-2"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-2 .57s linear forwards;
            -webkit-animation: circle-progress-animation-2 .57s linear forwards;
        }
        .inside-circle-numbers-animate-2 {
            animation: circle-number-animation-2 .57s linear forwards;
            animation-timing-function: steps(27);
            -webkit-animation: circle-number-animation-2 .57s linear forwards;
            -webkit-animation-timing-function: steps(27);
        }
    }
}

/* Circle 3: Progress & Counter */
@keyframes circle-progress-animation-3 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 152.68; // 2 * PI * 67.5 * (100 - 64) / 100
    }
}
@keyframes circle-number-animation-3 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -7680px; // 120 * 64
    }
}
[data-aos="circle-progress-animate-3"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-3 1.35s linear forwards;
            -webkit-animation: circle-progress-animation-3 1.35s linear forwards;
        }
        .inside-circle-numbers-animate-3 {
            animation: circle-number-animation-3 1.35s linear forwards;
            animation-timing-function: steps(64);
            -webkit-animation: circle-number-animation-3 1.35s linear forwards;
            -webkit-animation-timing-function: steps(64);
        }
    }
}

/* Circle 4: Progress & Counter */
@keyframes circle-progress-animation-4 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 122.99; // 2 * PI * 67.5 * (100 - 71) / 100
    }
}
@keyframes circle-number-animation-4 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -8520px; // 120 * 71
    }
}
[data-aos="circle-progress-animate-4"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-4 1.5s linear forwards;
            -webkit-animation: circle-progress-animation-4 1.5s linear forwards;
        }
        .inside-circle-numbers-animate-4 {
            animation: circle-number-animation-4 1.5s linear forwards;
            animation-timing-function: steps(71);
            -webkit-animation: circle-number-animation-4 1.5s linear forwards;
            -webkit-animation-timing-function: steps(71);
        }
    }
}

.box-margin-top {
    margin-top: 100px;
}
.box-margin-top-mobile {
    margin-top: 80px;
}

.center-message-body {
    flex: 0 0 100%;
}

.min-text-height-message {
    min-height: 4em;
}
.min-text-height-message-custom-tablet {
    min-height: 6em;
}

.min-text-height {
    min-height: 7.5em;
}
.min-text-height-mobile {
    min-height: 5em;
}

.min-text-footer-height {
    min-height: 3em;
}

.pos-absolute {
    position: absolute;
}
.pos-absolute-left-zero {
    left: 0;
}
.pos-absolute-bottom-zero {
    bottom: 0;
}

.black-background-opacity {
    background: #000000;
    opacity: .1;
}

.background-attachment-scroll {
    background-attachment: scroll;
}

.background-attachment-fixed {
    background-attachment: fixed;
}

.is-hero-top {
    position: fixed;
    width: 100%;
    z-index: -1;
}

.is-horizontal-center {
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.line-height-0 {
    line-height:0;
}

.foreground {
    position: relative;
    z-index: 1;
}

.img-box-padding {
    padding: 15px;
}

.is-vertical-center {
    display: flex;
    align-items: center;
}
.add-horizontal-center {
    justify-content: center;
}

.message-header-center {
    justify-content: center !important;
}

.sliderSwitch input {
    width: 0;
    height: 0;
    opacity: 0;
}
.sliderSwitch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
}
.sliderObject {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    cursor: pointer;
    background-color: hsl(0, 0%, 40%);
    -webkit-transition: .3s;
    transition: .3s;
}
.sliderObject:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto 0;
    height: 40px;
    width: 40px;
    content: '';
    -webkit-transition: .3s;
    transition: .3s;
    background-position: center;
    background-repeat: no-repeat;
}
.sliderCheckbox:checked + .sliderObject {
    background-color: #00917c;
}
.sliderCheckbox:checked + .sliderObject:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

.text-with-tooltip {
    color: #00917c;
}

.pointer-hand {
    cursor: pointer;
}
.dropdown-menu, .background {
    cursor: default !important;
}

.modal {
    flex-direction: unset !important;
}
.modal-headline-center {
    display: flex;
    justify-content: center;
}

.transition-up {
    transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
}
.move-up-on-hover:hover {
    transform: translateY(-5px);
}

.message-min-text-footer-height {
    min-height: 2em;
}
.message-footer {
    border-radius: 0 0 4px 4px;
}

.link-text {
    color: white !important;
    text-decoration: none !important;
}

.message-header-color-1 {
    background: #fa5555 !important;
}
.message-border-color-1 {
    border-color: #fa5555 !important;
}
.message-header-color-2 {
    background: #556cfa !important;
}
.message-border-color-2 {
    border-color: #556cfa !important;
}
.message-header-color-3 {
    background: #009e86 !important;
}
.message-border-color-3 {
    border-color: #009e86 !important;
}
.message-header-color-4 {
    background: #e59b30 !important;
}
.message-border-color-4 {
    border-color: #e59b30 !important;
}
.message-header-color-5 {
    background: #71af34 !important;
}
.message-border-color-5 {
    border-color: #71af34 !important;
}
.message-header-color-6 {
    background: #864dc6 !important;
}
.message-border-color-6 {
    border-color: #864dc6 !important;
}
.message-header-color-8 {
    background: hsl(204, 86%, 53%) !important;
}
.message-border-color-8 {
    border-color: hsl(204, 86%, 53%) !important;
}
.message-header-color-9 {
    background: #00917c !important;
}
.message-border-color-9 {
    border-color: #00917c !important;
}
.message-border-color-10 {
    border-color: #25d366 !important;
}
.message-border-color-11 {
    border-color: #288bfa !important;
}

.footer-icon-space-between {
    display: flex;
    justify-content: space-between;
}
.icon-box-small {
    width: 4em;
    height: 4em;
}
.icon-box-blog {
    padding: .25rem !important;
    min-width: 1.75em;
    height: 1.75em;
}

.action-button-page {
    position: fixed;
    z-index: 38;
    cursor:pointer;
    bottom: 20px;
    right: 20px;
}
.action-button-page-background {
    width: 4em;
    height: 4em;
}
.transition-action-button-page-leave-active,
.transition-action-button-page-enter-active {
    transition: opacity .3s;
}
.transition-action-button-page-leave-to,
.transition-action-button-page-enter {
    opacity: 0;
}
.scroll-to-element-background {
    padding-top: 1.5em;
    padding-left: 1em;
}
.easy-contact-to-element-background {
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-body a {
    text-decoration: none !important;
}

.themeSliderIcon:before {
    background-image: url('/img/moon.svg');
    background-size: 25px 25px;
}
.langSliderIcon:before {
    background-image: url('/img/english.svg');
    background-size: 25px 25px;
}

.text-color-1 {
    color: hsl(204, 86%, 53%) !important;
}
.text-color-2 {
    color: #00917c !important;
}

.tag-header {
    display: flex;
    flex-wrap: wrap;
}
.tag-block {
    display: inline-block;
    padding: .3em;
    margin: .1em;
    color: #fff;
}

.big-delete {
    width: 30px !important;
    height: 30px !important;
    max-height: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    min-width: 30px !important;
}

.display-inline-block {
    display: inline-block;
}

.display-none {
    display: none !important;
}

.border-radius-4 {
    border-radius: 4px;
}

.space-between-columns {
    width: 1em;
    height: 1em;
}
.space-between-columns-half {
    width: .5em;
    height: .5em;
}
.icons-right {
    justify-content: flex-end;
}
.icons-left {
    justify-content: flex-start;
}
.padding-left-right {
    padding-left: .5em !important;
    padding-right: .5em !important;
}

.text-primary {
    color: #00917c;
}

.kmso-contact-message {
    padding: 0.625em;
    font-size: 1rem;
}

.kmso-contact-message .textarea, .kmso-contact-message .input {
    padding: 0 !important;
    border: none !important;
    resize: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.margin-right-1 {
    margin-right: 1em;
}

.unset-width {
    width: unset !important;
}

.icon-color-1 {
    color: #25d366;
}

.icon-color-2 {
    color: #00917c;
}

.icon-color-3 {
    color: #288bfa;
}
.icon-color-4 {
    color: #e59b30;
}

.margin-top-ueber-uns {
    margin-top: 7em;
}
.margin-top-ueber-uns-invert {
    margin-top: -7em;
}
.margin-top-ueber-uns-invert-1 {
    margin-top: -6em;
}
.width-200px {
    width: 200px !important;
}
.height-200px {
    height: 200px !important;
}
.width-150px {
    width: 150px !important;
}
.height-150px {
    height: 150px !important;
}
.border-radius-100p {
    border-radius: 100%;
}

.overflow-hidden {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.text-right-align {
    justify-content: flex-end;
}

.slick-dots li button::before {
    font-size: 10px !important;
}

.carousel-margin {
    padding-left: 1px;
    padding-right: 1px;
}
.carousel-margin-bottom {
    margin-bottom: 1em;
}
.carousel-margin-bottom-2 {
    margin-bottom: 2em;
}
.min-text-height-mobile-2 {
    min-height: 3em;
}
.min-text-height-mobile-3 {
    min-height: 3em;
}

.margin-top-contact {
    margin-top: 1.5rem;
}

div {
    outline : 0;
}

.message-no-border {
    border-style: unset !important;
    border-width: unset !important;
    border-color: unset !important;
}

.margin-bottom-1 {
    margin-bottom: .5rem;
}

.margin-right-share {
    margin-right: -0.5rem;
}

.pagination-button {
    font-weight: bold;
    border: transparent;
}
.margin-right-pagination {
    margin-right: .25em;
}
.margin-left-pagination {
    margin-left: .25em;
}

address {
    font-style: normal !important;
}

.slick-dots li button::before {
    opacity: .25 !important;
}
.slick-dots li.slick-active button::before {
    opacity: 1 !important;
}

.button:focus {
    box-shadow: none !important;
}

.blog-social-icons-mobile {
    width: 60% !important;
    height: 60% !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
}

.title-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.footer-icon-space {
    margin-left: 1em;
    margin-right: 1em;
}

.ueber-uns-message-body-padding {
    padding-left: .25em !important;
    padding-right: .25em !important;
}

.custom-checkbox-wrapper {
    display: inline-block;
    position: relative;
}

.rocket-transition {
    transition: top .2s ease-out;
    -o-transition: top .2s ease-out;
    -moz-transition: top .2s ease-out;
    -webkit-transition: top .2s ease-out;
}

.hero-box {
    display: inline-block;
    padding: 1.5em;
    border-radius: 1em;
}
.hero-box-background-desktop {
    background-color: rgba(2, 1, 17, .8);
}
.hero-box-background-mobile {
    background-color: rgba(2, 1, 17, .4);
}

.hero-margin-left {
    margin-left: 1.5em;
}
